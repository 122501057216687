:root {
  --dropdown-bg-color: grey;
  --text-primary: #1976d2;
  --text-warning: rgb(255 0 0 / 78%);
  --primary: #1976d2;
  --secondary: rgb(220, 0, 78);
  --text-grey: #60666dab;
  --font-family-default: Arial, Helvetica, sans-serif;
}

._qGthJ {
  font-family: var(--font-family-default);
  box-sizing: border-box;
  letter-spacing: -0.2px;
}

._qGthJ input {
  font-family: var(--font-family-default);
}

._qGthJ iframe,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p,
blockquote,
pre,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
figure,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
form,
fieldset,
legend,
a,
textarea,
input,
select,
option,
button,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

._qGthJ,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

button {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 10px;
}

/* indicator */

._3Kg-b {
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-bottom: 5em;
  margin-top: 3em;
}

._3Kg-b table {
  border-spacing: 0px;
  display: table;
  box-sizing: border-box;
  border-color: unset;
}

._18XWA {
  width: 100em;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

._18XWA td,
tr,
th,
thead {
  vertical-align: baseline;
  text-align: center;
}

._18XWA td,
th {
  padding: 1.2em 0.7em;
  font-size: 15px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

._3lsIe {
  padding: 1.5em;
  text-align: left;
}

._PynUC {
  display: flex;
  justify-content: space-between;
}

._PynUC span:last-child {
  color: black;
  font-size: 15px;
  letter-spacing: 0.4px;
  font-weight: 300;
}
._32kWB {
  margin: 0;
  text-align: left;
  color: var(--text-primary);
  font-size: 17px;
}

._2lGkw {
  font-size: 14px;
}
